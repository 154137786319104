export const API_BASE_URL = 'https://al-ameen-cms.shtdevops.xyz/'
export const API_IMAGE_URL = 'https://al-ameen-cms.shtdevops.xyz'
export const API_BASE_URL_MEDIA_SERVER = 'https://al-ameen-media.shtdevops.xyz/'
export const CRM_API_BASE_URL = 'https://al-ameen-api.shtdevops.xyz/web/'
// export const API_BASE_URL = 'http://192.168.1.193:8003/'
// export const API_IMAGE_URL = 'http://192.168.1.193:8003'
// export const API_BASE_URL_MEDIA_SERVER = 'http://192.168.1.193:4001/'
// export const CRM_API_BASE_URL = 'http://192.168.1.193:8041/web/'

export const FLAG_BASE_URL = 'https://alameen-api.shtdevops.xyz/'
export const CAPTCHA_KEY = '6LcofQ4qAAAAANs1oS0Lf1aCABTeMg8U-ooPF5XV'
export const API_INSTA_TOKEN = "IGQWRPU2RaYzdTSUcyNExKdXNTZAHJzVDhub2ZAoYmVMMHBJYVdROHpQOHF0b1EtcGFWaVVjYzRRa3pjeTBQXzRQZA1pUM1NlZAmVmbW5PVnFUaG5URHhxSU9ZAS3BCcmgxdWI2ZA0J5Ri0zUE1NSDVKVWoybzBnU2F4TzAZD"