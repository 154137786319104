import React, { useEffect, useRef, useState } from 'react'
import Title from '../components/Title/Title'
import { Accordion } from 'react-bootstrap'
import { aboutUs } from '../actions'
import { API_BASE_URL } from '../configuration'
import { useTranslation } from 'react-i18next'
import useTranslate from '../components/useTranslate'
import MetaHead from '../components/MetaHead/MetaHead'
export default function About() {
  const { i18n } = useTranslation();
  const { t } = useTranslate();
  const [data, setData] = useState()
  const [isReadMore, setIsReadMore] = useState(true);
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    aboutUs((res) => {
      setData(res)
    })
  }, [])

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const isLongDescription = (data) => {
    return data?.about_description?.length > 645;
  };


  return (
    <>
       <MetaHead
        title={data?.meta_title}
        desc={data?.meta_description}
        keyword={data?.meta_keyword}
      />
      <section className='alameen__aboutUs'>
        <div className="container">
          <div className="row align-items-end rtl-item-center sm-vh-auto">
            <div className={"col-md-5 col-tab-12"} style={{ display: !isReadMore && 'none' }}>
              <div className="alameen__about" data-aos="fade-up" data-aos-duration="2000">
                <img src="assets/img/dark-about.svg" className='w-100 about about-dark' alt="" />
                <img src="assets/img/light-about.svg" className='w-100 about about-light' alt="" />
              </div>
            </div>
            <div className={!isReadMore ? "col-md-12 col-tab-12 mt-5 fade-in" : "col-md-6 col-tab-12 fadeIn"}>
              <div className="alameen__about" data-aos="fade-up" data-aos-duration="3000">
                <Title title={langSwitch ? data?.about_title : data?.about_title_ar} />
                {
                
                  langSwitch  ? <p dangerouslySetInnerHTML={{ __html: isReadMore ? data?.about_description.slice(0, 643) : data?.about_description }}/> : 
                  <p dangerouslySetInnerHTML={{ __html: isReadMore ? data?.about_description_ar.slice(0, 643) : data?.about_description_ar }}/>
                }
                {
                  isLongDescription(data) &&
                  <span onClick={toggleReadMore}>
                  <span className='text-primary'>{isReadMore ? (t('Read More')) : (t('Read Less'))}</span>
                </span>
                }
                
                {/* <p dangerouslySetInnerHTML={{ __html: langSwitch ? data?.about_description : data?.about_description_ar }}></p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="importance__alameen">
        <div className="container">
          <Title title={langSwitch ? data?.main_title : data?.main_title_ar} fontSize={20} fontWeight={400} />
          <p dangerouslySetInnerHTML={{ __html: langSwitch ? data?.description : data?.description_ar }} ></p>
          <div className="visionMission">
            <div className="gridItem" >
              <div className="icon">
                <img src={API_BASE_URL + data?.file_path + data?.vision_icon} alt="" />
                <h3>{langSwitch ? data?.vision_title : data?.vision_title_ar}</h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: langSwitch ? data?.vision_description : data?.vision_description_ar }}></p>
            </div>
            <div className="gridItem">
              <div className="icon">
                <img className='mission' src={API_BASE_URL + data?.file_path + data?.mission_icon} alt="" />
                <h3>{langSwitch ? data?.mission_title : data?.mission_title_ar}</h3>
              </div>
              <p dangerouslySetInnerHTML={{ __html: langSwitch ? data?.mission_description : data?.mission_description_ar }}></p>
            </div>
          </div>
        </div>
      </section>
      <section className="about__collpase">
        <div className="container">
          <div className="type__label">
            <h3>{langSwitch ? data?.value_title_en : data?.value_title_ar}</h3>
          </div>
          <Accordion defaultActiveKey="0">
            {data?.values.map((ele, i) => {
              return (
                <Accordion.Item eventKey={`${i}`} data-aos="fade-up" key={i}>
                  <Accordion.Header>
                    <div className="titleCallpase">
                      <img src={`${API_BASE_URL}${data?.file_path}${ele?.value_icon}`} alt="" />
                      <h4>{langSwitch ? ele?.title : ele?.title_ar}</h4>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p dangerouslySetInnerHTML={{ __html: langSwitch ? ele?.description : ele?.description_ar }}></p>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </section>
    </>
  )
}
